import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import CustomEncoder from '../helpers/customer-encoder';

@Injectable()
export class ConfigService {
  constructor() { }

  getParamsProject(params = {}): any {
    let httpParams = new HttpParams({ encoder: new CustomEncoder() });

    for (const key of Object.keys(params)) {
      httpParams = httpParams.append(key, params[key] instanceof Object ? JSON.stringify(params[key]) : params[key]);
    }

    return httpParams;
  }

  objectToQueryStringParams(object = {}): string {
    let queryStringParams = ``;

    if (object && Object.keys(object).length) {
      Object.keys(object).forEach((key, index) => {
        queryStringParams += `${index !== 0 ? '&' : ''}${key}=${object[key]}`;
      });
    }

    return queryStringParams;
  }
}
