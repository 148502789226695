import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClearStateNamePipe } from '../pipe/clear-state-name.pipe';
import { SafePipe } from '../pipe/safe.pipe';
import { PipeComponent } from '../pipe/pipe.component';
import { DisabilityTypePipe } from '../pipe/disability-type.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ClearStateNamePipe,
    SafePipe,
    PipeComponent,
    DisabilityTypePipe
  ],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ClearStateNamePipe,
    SafePipe,
    PipeComponent,
    DisabilityTypePipe
  ],
})
export class CoreModule { }
