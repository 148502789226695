import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: '<app-notice-cookies></app-notice-cookies><router-outlet></router-outlet>',
})
export class AppComponent {

  constructor() { }
}
