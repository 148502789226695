import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserAgentGuard implements CanActivate {

  constructor (
    @Inject(PLATFORM_ID)
    private platformId: Object,
    private router: Router
  ) { }

  canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.getBrowserName() === 'ie') {
      return this.router.navigate(['/incompatible-browser']);
    } else {
      return true;
    }
  }

  public getBrowserName () {
    if (isPlatformBrowser(this.platformId)) {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf('edge') > -1:
          return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
          return 'chrome';
        case agent.indexOf('trident') > -1:
          return 'ie';
        case agent.indexOf('firefox') > -1:
          return 'firefox';
        case agent.indexOf('safari') > -1:
          return 'safari';
        default:
          return 'other';
      }
    }
  }
}
