export const environment = {
  production: true,
  url: 'https://ciee-api.taqe.com.br/v2',
  branch_key: 'key_live_ooPFZuZgNByFToaNP0Bm3fooAriIAP3q',
  link_branch: 'https://ciee-one.app.link/',
  url_pwa: 'https://ciee-app.taqe.com.br',
  type: 'ciee',
  default_SEO_img: 'https://storage.googleapis.com/ciee-storage/production/public/companies/logos/ciee_25-10-2021-12-32-50-676_T.png',
  url_landing: 'https://ciee-vagas.taqe.com.br',
  whiteLabel: {
    ciee: {
      url_pwa: 'https://ciee-app.taqe.com.br',
    }
  }
};
