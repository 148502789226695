import { Routes } from '@angular/router';

import { UserAgentGuard } from './core/guards/user-agent.guard';

export const AppRoutes: Routes = [
  {
    path: 'incompatible-browser',
    loadChildren: () => import('./incompatible-browser/incompatible-browser.module').then(m => m.IncompatibleBrowserModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('./core/cookies/cookies.module').then(m => m.CookiesModule)
  },
  {
    path: 'static',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./static-landing-page/static-landing-page.module').then(m => m.StaticLandingPageModule)
  },
  {
    path: 'interno',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/internal/companies-details-internal.module').then(m => m.CompaniesDetailsInternalModule)
  },
  {
    path: 'quem-indica',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/indications/companies-details-indication.module').then(m => m.CompaniesDetailsIndicationModule)
  },
  {
    path: 'exclusivos',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/indications/companies-details-indication.module').then(m => m.CompaniesDetailsIndicationModule)
  },
  {
    // APENAS PARA OS PROJETOS DO MEGA LUZZ.
    path: 'negocios',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/indications/companies-details-indication.module').then(m => m.CompaniesDetailsIndicationModule)
  },
  {
    // APENAS PARA OS PROJETOS DO VERNALHA.
    path: 'trainee',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/indications/companies-details-indication.module').then(m => m.CompaniesDetailsIndicationModule)
  },
  {
    // APENAS PARA OS PROJETOS DA MOVILE.
    path: 'mobile-dream-indicacao',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/indications/companies-details-indication.module').then(m => m.CompaniesDetailsIndicationModule)
  },
  {
    path: 'preview/:companyKey',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule)
  },
  {
    path: ':companyKey',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule)
  },
  {
    path: '',
    canActivate: [UserAgentGuard],
    loadChildren: () => import('./vacancies/vacancies.module').then(m => m.VacanciesModule)
  }
];
