import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { VacancyDetails } from '../models/vacancy-details';

// Service's
import { ConfigService } from './config.service';

@Injectable()
export class VacancyService {
  url: string = environment.url;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  getVacancyDetails(companyKey, vacancyKey, params = {}): Observable<VacancyDetails> {
    const httpParams = this.configService.getParamsProject(params);

    return this.http.get<VacancyDetails>(`${this.url}/public/vacancies/${companyKey}/${vacancyKey}`, { params: httpParams });
  }

  getVacancyPreview(companyKey, vacancyKey, params = {}): Observable<VacancyDetails> {
    const httpParams = this.configService.getParamsProject(params);

    return this.http.get<VacancyDetails>(`${this.url}/public/vacancies/preview/${companyKey}/${vacancyKey}`, { params: httpParams });
  }

  getVacancies(params = {}): Observable<any> {
    const httpParams = this.configService.getParamsProject(params);

    return this.http.get<any>(`${this.url}/public/vacancies`, { params: httpParams });
  }

  getVacanciesByCompany(companyKey, params = {}): Observable<any> {
    const httpParams = this.configService.getParamsProject(params);

    return this.http.get<any>(`${this.url}/public/vacancies/${companyKey}/vacancies`, { params: httpParams });
  }

  getPrivateVacancies(companyKey, params = {}): Observable<any> {
    const httpParams = this.configService.getParamsProject(params);

    return this.http.get<any>(`${this.url}/public/vacancies/${companyKey}/private/vacancies`, { params: httpParams });
  }
}
