import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import { AdsenseModule } from 'ng2-adsense';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt);

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

// Service
import { VacancyService } from './core/services/vacancy.service';
import { ConfigService } from './core/services/config.service';

// Module
import { MaterialModule } from './material.module';
import { CoreModule } from './core/core.module';

// Interceptor
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

import { environment } from '../environments/environment';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.url_landing.replace(/(https?:\/\/)(\w.+)/g, '$2').replace(/:\d{0,4}/g, '')
  },
  position: 'bottom',
  theme: 'edgeless',
  palette: {
    popup: {
      background: '#333333',
      text: '#ffffff'
    },
    button: {
      background: '#5128e2',
      text: '#ffffff'
    }

  },
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messageLink}}{{compliance}}'
  },
  elements: {
    messageLink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}}
      <a aria-label="leia mais sobre a politíca de cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a> e
      <a aria-label="leia mais sobre a politíca de privacidade" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>.
    </span>
    `,
  },
  content: {
    message: "Ao acessar o nosso site, você concorda com o uso de cookies para analisar e produzir conteúdo e anúncios adaptados aos seus interesses. Leia a nossa",
    dismiss: "Entendi!",
    allow: "Aceitar Cookies",
    cookiePolicyLink: 'política de utilização de cookie',
    cookiePolicyHref: '/policy/cookies-policy',
    privacyPolicyLink: 'política de privacidade.',
    privacyPolicyHref: '/policy/privacy-policy',
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    MaterialModule,
    BrowserModule.withServerTransition({ appId: 'taqeVagas' }),
    TransferHttpCacheModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabledNonBlocking' }),
    ScrollToModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    // AdsenseModule.forRoot({
    //   adClient: 'ca-pub-8953887939300326'
    // }),
    TextMaskModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxJsonLdModule,
    CarouselModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    InfiniteScrollModule,
    CoreModule
  ],
  providers: [
    VacancyService,
    ConfigService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
